@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --coffee: #ac7b58;
  --main: #011f26;
  --bg: #f4f8fd;
  --black: #000000;
  --sidebar_width: 220px;
  --transition: all 0.5s ease-in-out;
  --default_pad: 15px;
  --hamburger: 40px;
  --header_height: 60px;
  --page_title_height: 60px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #f4f8fd;
  font-family: "Manrope", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login_container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.panel_container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  background-color: white;
}
.sidebar_container {
  position: absolute;
  top: 0;
  left: calc(var(--sidebar_width) * -1);
  border-right: 2px solid var(--main);
  height: 100vh;
  width: var(--sidebar_width);
  background-color: var(--bg);
  padding: var(--default_pad);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  z-index: 2;
}
.sidebar_container.open {
  left: 0;
}
.sidebar_title {
  color: var(--main);
  text-align: center;
  font-size: 22px;
  line-height: calc(var(--hamburger) - 6px);
  margin-right: 50px;
}
.panel_content_container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.panel_content_container.large {
  padding: 0 var(--sidebar_width);
}
.panel_content_container.medium {
  padding-right: calc(var(--sidebar_width) / 2);
  padding-left: calc(var(--sidebar_width) / 2);
}
.panel_content_container.medium.open {
  padding-right: 0;
  padding-left: var(--sidebar_width);
}
.panel_content_container.small {
  padding: 0;
}
.panel_inside {
  display: block;
  height: calc(100vh - var(--header_height));
  width: auto;
  padding: var(--default_pad);
  background-color: var(--white);
}
#hamburger-1 {
  position: absolute;
  top: var(--default_pad);
  right: calc((var(--default_pad) * 2 + var(--hamburger)) * -1);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
#hamburger-1 .line {
  width: var(--hamburger);
  height: 5px;
  background-color: var(--main);
  display: block;
  margin: 5px auto;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
#hamburger-1:hover {
  cursor: pointer;
}
#hamburger-1.is-active {
  right: var(--default_pad);
}
#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}
#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}
#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}
.navbar_container {
  display: flex;
  height: var(--header_height);
  width: auto;
  padding: var(--default_pad);
  border-bottom: 2px solid var(--main);
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.sidebar_items_contaier {
  padding: 20px 0;
}
.sidebar_item_container {
  margin-bottom: 15px;
}
.capitalize {
  font-size: 20px;
  font-weight: 400;
  color: var(--black);
  text-decoration: none;
}
.capitalize_selected {
  font-size: 20px;
  font-weight: 600;
  color: var(--main);
  text-decoration: none;
  text-transform: uppercase;
}
input[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}
input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}
input[type="email"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}
input[type="email"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}
textarea[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}
textarea[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}
.term_container {
  margin-top: 100px;
  width: 92%;
  margin-left: 4%;
}
.term_container h1 {
  text-align: center;
}
.term_container p {
  word-break: break-word;
  white-space: pre-wrap;
}
.page_title_container {
  height: var(--page_title_height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page_title_container h1 {
  display: flex;
  font-size: 32px;
  align-items: center;
}
.table_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  overflow-x: hidden;
  height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
  max-height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
}
.edit_image {
  cursor: pointer;
  margin-right: 10px;
}
.delete_image {
  cursor: pointer;
}
.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--header_height) - 2 * var(--default_pad));
}
.back_image {
  cursor: pointer;
  margin-bottom: 15px;
}
.choices_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.choice_container {
  margin-right: 15px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  background-color: #e3faff;
}
.choice_container label input {
  margin-right: 5px;
}
.App1 {
  height: 75vh;
  margin-top: 15vh;
}
form {
  margin-top: 20px;
}
.formRow {
  margin: 10px 0 20px 0;
}
.formInput {
  padding: 15px 10px;
}
.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.submit-btn {
  padding: 15px 50px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.suspend_container {
  display: flex;
  direction: row;
}
.suspend_button {
  padding: 6px;
  cursor: pointer;
  border-radius: 6px;
  border-width: 1px;
  border-color: var(--main);
  margin-right: 6px;
}

/* RESPONSIVE */
@media (max-width: 1100px) {
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}
